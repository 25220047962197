<template>
  <simpleNotifyPage
    :action="action"
    barType="error"
  >
    <template v-slot:title> 取消合作 </template>
    <template v-slot:subtitle> 已取消{{ storeName }}的合作關係 </template>
    <template v-slot:text-label> 合作狀態： </template>
    <template v-slot:text-value> 已取消合作 </template>
  </simpleNotifyPage>
</template>

<script>
export default {
  components: {
    simpleNotifyPage: () =>
      import("@/modules/base/views/notifyPage/simpleNotifyPage.vue"),
  },
  computed: {
    storeName() {
      return this.$route.query.name;
    },
    storeId() {
      return this.$route.query.storeId;
    },
    providerId() {
      return this.$route.query.providerId;
    },
  },
  methods: {
    action() {
      this.$helper.closeLiff()
    },
  },
};
</script>
